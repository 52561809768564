/* eslint-disable @typescript-eslint/no-magic-numbers */
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled, { css, CSSObject } from 'styled-components';

import { zIndexes } from '@core/constant';
import { EColor, EFontWeight, EZIndexName, WithThemeProps } from '@core/type';
import { getPaletteHandlers } from '@core/util';

import { Box } from '../layout';
import { InputRangeProps } from './interface-input';

const POINT_WIDTH = 18;

export const InputRange = ({
  min,
  max,
  step = 1,
  defaultValue = min,
  onChange,
  onMouseUp,
  tooltipValue,
  hasVisibleTooltip,
  hasTooltip = true,
}: InputRangeProps) => {
  const [value, setValue] = useState<number>(defaultValue);
  const sliderRef = useRef<HTMLInputElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setValue(Number(value));

    if (onChange) {
      onChange(Number(value));
    }
  };

  const handleMouseUp = (target: EventTarget) => {
    const { value } = target as HTMLInputElement;

    if (onMouseUp) {
      onMouseUp(Number(value));
    }
  };

  useEffect(() => {
    const updateTooltipPosition = () => {
      const slider = sliderRef.current;
      const tooltip = tooltipRef.current;

      if (slider && tooltip) {
        const sliderRect = slider.getBoundingClientRect();
        const tooltipRect = tooltip.getBoundingClientRect();
        const minValue = tooltipRect.width / 2;
        const maxValue = sliderRect.width - tooltipRect.width / 2;
        const pointPosition = ((value - min) / (max - min)) * (sliderRect.width - POINT_WIDTH);
        const tooltipLeft = pointPosition + POINT_WIDTH / 2;

        tooltip.style.left = `${Math.min(Math.max(tooltipLeft, minValue), maxValue)}px`;
      }
    };

    updateTooltipPosition();

    window.addEventListener('resize', updateTooltipPosition);
    return () => {
      window.removeEventListener('resize', updateTooltipPosition);
    };
  }, [value, min, max]);

  return (
    <Box position={'relative'} width={'100%'}>
      <Track>
        {/* eslint-disable-next-line @typescript-eslint/no-magic-numbers */}
        <TrackFill width={((value - min) / (max - min)) * 100} />
      </Track>
      <StyledInput
        id={'input-range'}
        ref={sliderRef}
        type={'range'}
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
        onMouseUp={({ target }) => handleMouseUp(target)}
        onTouchEnd={({ target }) => handleMouseUp(target)}
      />
      {hasTooltip && (
        <Tooltip ref={tooltipRef} hasVisibleTooltip={hasVisibleTooltip}>
          {tooltipValue || value}
        </Tooltip>
      )}
    </Box>
  );
};

const Track = styled(Box)(({ theme: { palette } }: WithThemeProps) => {
  const { getColor } = getPaletteHandlers(palette);

  return css`
    z-index: ${zIndexes[EZIndexName.DEFAULT]};
    position: absolute;
    top: calc(50% + 3px);
    left: 0;
    width: 100%;
    height: 4px;
    border-radius: 10px;
    transform: translateY(-50%);
    background-color: ${getColor({ commonType: EColor.GRAY, intensity: EColor.A100 })};
  `;
});

const TrackFill = styled(Box)(({
  theme: { palette },
  width,
}: WithThemeProps & { width: number }) => {
  const { getColor } = getPaletteHandlers(palette);

  return css`
    height: 100%;
    width: ${width}%;
    border-radius: 10px;
    background-color: ${getColor(EColor.SECONDARY)};
  `;
});

const Tooltip = styled(Box)(({
  theme: {
    palette,
    typography: { fontWeight },
  },
  hasVisibleTooltip,
}: WithThemeProps & { hasVisibleTooltip: boolean }) => {
  const { getColor, getTextColor, getBoxShadow } = getPaletteHandlers(palette);

  return css`
    opacity: ${hasVisibleTooltip ? '1' : '0'};
    pointer-events: ${hasVisibleTooltip ? 'auto' : 'none'};
    z-index: ${zIndexes[EZIndexName.OVER_DEFAULT]};
    position: absolute;
    bottom: calc(${POINT_WIDTH}px + 4px);
    transform: translateX(-50%);
    padding: 0 15px;
    font-size: 14px;
    font-weight: ${fontWeight[EFontWeight.BOLD]};
    white-space: nowrap;
    border-radius: 4px;
    transition: opacity 0.2s;
    color: ${getTextColor(EColor.PRIMARY)};
    box-shadow: ${getBoxShadow(EColor.ELEVATION_4)};
    background-color: ${getColor({ commonType: EColor.WHITE, intensity: EColor.R900 })};
  `;
});

const StyledInput = styled.input(
  ({
    theme: { palette },
  }: WithThemeProps & {
    onMouseUp?: (event: React.MouseEvent<HTMLInputElement>) => void;
    onTouchEnd?: (event: React.TouchEvent<HTMLInputElement>) => void;
  }) => {
    const { getColor } = getPaletteHandlers(palette);

    const pointStyles = {
      cursor: 'pointer',
      borderRadius: '50%',
      width: `${POINT_WIDTH}px`,
      height: `${POINT_WIDTH}px`,
      background: getColor(EColor.SECONDARY),
    };

    return css`
      z-index: ${zIndexes[EZIndexName.OVER_DEFAULT]};
      position: relative;
      width: 100%;
      height: 4px;
      appearance: none;
      outline: none;
      margin: 0;
      padding: 0;
      border-radius: 10px;
      background: transparent;
      cursor: pointer;

      &:hover ~ div {
        opacity: 1;
        pointer-events: auto;
      }

      &::-webkit-slider-thumb {
        ${pointStyles as CSSObject};
        appearance: none;
      }

      &::-moz-range-thumb {
        ${pointStyles as CSSObject};
      }
    `;
  },
);
